const config = {
  apiGateway: {
    REGION: 'us-west-2',
    INTERNAL_API_URL: 'https://qfvk9l2805.execute-api.us-west-2.amazonaws.com/prod',
    TEAM_API_URL: 'https://a2ovacxfta.execute-api.us-west-2.amazonaws.com/api'
  },
  reactApp: {
    VERSION: 'taxonomy-teams-1.10.1',
    HOSTNAME: 'https://teams-service.dev.pluto.forwoodsafety.com'
  },
  configurationService: {
    URL: 'https://config.dev.pluto.forwoodsafety.com',
    WEBSOCKET: 'wss://hhepu5o7r3.execute-api.us-west-2.amazonaws.com/dev'
  },
};

export default config;
